<template>
  <div>
    <v-row>
      <v-col cols="12" md="9" sm="12">
        <v-row>
          <v-col cols="12" md="4" sm="12" xs="12" class="pt-0">
            <v-card color="elevation-4" outlined shaped>
              <v-card-text class="py-3 px-1">
                <v-list-item>
                  <v-list-item-content class="">
                    <v-list-item-subtitle>
                      DID Net Additions ({{
                        didHistory.netDidsCurrentDay
                      }})</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1">
                      <span class="lstick"></span>

                      {{ didHistory.netDidsDiff }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-avatar color="info lighten-1" class="elevation-4">
                    <v-icon dark> call </v-icon>
                  </v-avatar>
                </v-list-item>
                <v-card-subtitle class="py-0">
                  <v-icon color="green" size="20">arrow_upward </v-icon>
                  <span class="ml-1">{{ didHistory.netDIDsStatus }}</span>
                </v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12" class="pt-0">
            <v-card color="elevation-4" outlined shaped>
              <v-card-text class="py-3 px-1">
                <v-list-item>
                  <v-list-item-content class="">
                    <v-list-item-subtitle>
                      New DID Activations({{
                        didHistory.activeDidsCurrentDay
                      }})</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1">
                      <span class="lstick"></span>

                      {{ didHistory.activeDidsDiff }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-avatar color="info lighten-1" class="elevation-4">
                    <v-icon dark> call </v-icon>
                  </v-avatar>
                </v-list-item>
                <v-card-subtitle class="py-0">
                  <v-icon color="green" size="20">arrow_upward </v-icon>
                  <span class="ml-1">{{ didHistory.activeDIDsStatus }}</span>
                </v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12" class="pt-0">
            <v-card color="elevation-4" outlined shaped>
              <v-card-text class="py-3 px-1">
                <v-list-item>
                  <v-list-item-content class="">
                    <v-list-item-subtitle>
                      Outbound call limit</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1">
                      <span class="lstick"></span>

                      {{ outboundCallLimitStatus }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-avatar color="green lighten-1" class="elevation-4">
                    <v-icon dark> bar_chart </v-icon>
                  </v-avatar>
                </v-list-item>
                <v-card-subtitle class="py-0">
                  <span class="ml-1 d-flex">
                    <!-- {{ didHistory.inboundMinStatus }} -->
                    <v-icon color="green" size="20" class="pr-1"
                      >arrow_upward
                    </v-icon>

                    if you wish to increase limit of concurrent outbound calls,
                    kindly contact support.
                  </span>
                </v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" sm="12" xs="12">
            <v-card color="elevation-4" outlined shaped>
              <v-card-text class="py-3 px-1">
                <v-list-item>
                  <v-list-item-content class="">
                    <v-list-item-subtitle>
                      Average Minutes Per DID</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1">
                      <span class="lstick"></span>

                      {{ didHistory.inboundAverageDiff }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-avatar color="green lighten-1" class="elevation-4">
                    <v-icon dark> bar_chart </v-icon>
                  </v-avatar>
                </v-list-item>
                <v-card-subtitle class="py-0">
                  <v-icon color="green" size="20">arrow_upward </v-icon>
                  <span class="ml-1">{{
                    didHistory.inboundAverageStatus
                  }}</span>
                </v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <v-card color="elevation-4" outlined shaped>
              <v-card-text class="py-3 px-1">
                <v-list-item>
                  <v-list-item-content class="">
                    <v-list-item-subtitle>
                      Outbound Minutes</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1">
                      <span class="lstick"></span>

                      {{ didHistory.currentDayOutboundMins }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-avatar color="yellow darken-2" class="elevation-4">
                    <v-icon dark> call </v-icon>
                  </v-avatar>
                </v-list-item>
                <v-card-subtitle class="py-0">
                  <v-icon color="green" size="20">arrow_upward </v-icon>
                  <span class="ml-1">{{ didHistory.outboundMinStatus }}</span>
                </v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="4" sm="12" xs="12">
            <v-card color="elevation-4" outlined shaped>
              <v-card-text class="py-3 px-1">
                <v-list-item>
                  <v-list-item-content class="">
                    <v-list-item-subtitle>
                      Outbound Traffic Cost</v-list-item-subtitle
                    >
                    <v-list-item-title class="headline mb-1">
                      <span class="lstick"></span>

                      {{ didHistory.outboundRateDiff }}
                    </v-list-item-title>
                  </v-list-item-content>

                  <v-avatar color="yellow darken-2" class="elevation-4">
                    <v-icon dark> call </v-icon>
                  </v-avatar>
                </v-list-item>
                <v-card-subtitle class="py-0">
                  <v-icon color="green" size="20">arrow_upward </v-icon>
                  <span class="ml-1"> {{ didHistory.outboundRateStatus }}</span>
                </v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <!-- <v-row>
          <v-col lg="4" md="4" sm="12" cols="12">
            <div class="custom-card" style="background-color: #4dd4fd">
              <div class="card-refresh-icon">
                <v-icon>autorenew</v-icon>
              </div>

              <div class="mt-5">
                <h2>{{ didHistory.netDidsDiff }}</h2>
                <h4>
                  DID Net Additions ({{ didHistory.netDidsCurrentDay }} Total)
                </h4>
                <v-icon small color="white">arrow_circle_down</v-icon>
                <span class="fa-lg">{{ didHistory.netDIDsStatus }}</span>

                <div class="custom-card-background-icon">
                  <v-icon style="font-size: 70px" color="white">call</v-icon>
                </div>
              </div>
            </div>
          </v-col>

          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="custom-card" style="background-color: #4dd4fd">
              <div class="card-refresh-icon">
                <v-icon>autorenew</v-icon>
              </div>

              <div class="mt-5">
                <h2>{{ didHistory.activeDidsDiff }}</h2>
                <h4>
                  New DID Activations({{ didHistory.activeDidsCurrentDay }})
                </h4>
                <v-icon small color="white">trip_origin</v-icon>
                <span class="fa-lg">{{ didHistory.activeDIDsStatus }}</span>

                <div class="custom-card-background-icon">
                  <v-icon style="font-size: 70px" color="white">call</v-icon>
                </div>
              </div>
            </div>
          </v-col>

          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="custom-card" style="background-color: #9ad268">
              <div class="card-refresh-icon">
                <v-icon>autorenew</v-icon>
              </div>

              <div class="mt-5">
                <h2>{{ didHistory.currentDayInboundMins }}</h2>
                <h4>Inbound Minutes</h4>
                <v-icon small color="white">arrow_circle_down</v-icon>
                <span class="fa-lg">{{ didHistory.inboundMinStatus }}</span>

                <div class="custom-card-background-icon">
                  <v-icon style="font-size: 70px" color="white"
                    >bar_chart</v-icon
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="custom-card" style="background-color: #9ad268">
              <div class="card-refresh-icon">
                <v-icon>autorenew</v-icon>
              </div>

              <div class="mt-5">
                <h2>{{ didHistory.inboundAverageDiff }}</h2>
                <h4>Average Minutes Per DID</h4>
                <v-icon small color="white">arrow_circle_down</v-icon>
                <span class="fa-lg">{{ didHistory.inboundAverageStatus }}</span>

                <div class="custom-card-background-icon">
                  <v-icon style="font-size: 70px" color="white"
                    >bar_chart</v-icon
                  >
                </div>
              </div>
            </div>
          </v-col>
          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="custom-card" style="background-color: #ffc545">
              <div class="card-refresh-icon">
                <v-icon>autorenew</v-icon>
              </div>

              <div class="mt-5">
                <h2>{{ didHistory.currentDayOutboundMins }}</h2>
                <h4>Outbound Minutes</h4>
                <v-icon small color="white">arrow_circle_down</v-icon>
                <span class="fa-lg">{{ didHistory.outboundMinStatus }}</span>

                <div class="custom-card-background-icon">
                  <v-icon style="font-size: 70px" color="white">call</v-icon>
                </div>
              </div>
            </div>
          </v-col>
          <v-col lg="4" md="4" sm="6" cols="12">
            <div class="custom-card" style="background-color: #ffc545">
              <div class="card-refresh-icon">
                <v-icon>autorenew</v-icon>
              </div>

              <div class="mt-5">
                <h2>{{ didHistory.outboundRateDiff }}</h2>
                <h4>Outbound Traffic Cost</h4>
                <v-icon small color="white">arrow_circle_down</v-icon>
                <span class="fa-lg">{{ didHistory.outboundRateStatus }}</span>

                <div class="custom-card-background-icon">
                  <v-icon style="font-size: 70px" color="white"
                    >shopping_cart</v-icon
                  >
                </div>
              </div>
            </div>
          </v-col>
        </v-row> -->
        <!-- <v-row>
          <v-col cols="12" lg="12">
            <v-card>
              <v-col cols="12">
                <v-row>
                  <v-col lg="6" md="6" sm="12">
                    <v-card flat outlined>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <v-avatar color="#9ad268">
                            <v-icon dark>support_agent</v-icon>
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="10" md="10">
                          <v-list-item-content>
                            <v-list-item-title
                              >0</v-list-item-title
                            >
                            <v-list-item-subtitle
                              ><a
                                :href="`mailto:${productStoreData.CUSTOMER_CARE}`"
                                style="text-decoration: none; color: grey"
                                >{{ productStoreData.CUSTOMER_CARE }}</a
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col lg="6" md="6" sm="12">
                    <v-card flat outlined>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <v-avatar color="#e371bc">
                            <v-icon dark>person</v-icon>
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="10" md="10">
                          <v-list-item-content>
                            <v-list-item-title>
                              Account Manager
                            </v-list-item-title>
                            <v-list-item-subtitle
                              ><a
                                :href="`mailto:${productStoreData.ACC_MGR}`"
                                style="text-decoration: none; color: grey"
                                >{{ productStoreData.ACC_MGR }}</a
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-row>
                  <v-col lg="6" md="6" sm="12">
                    <v-card flat outlined>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <v-avatar color="#ac71e3">
                            <v-icon dark>location_on</v-icon>
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="10" md="10">
                          <v-list-item-content>
                            <v-list-item-title> LNP </v-list-item-title>
                            <v-list-item-subtitle
                              ><a
                                :href="`mailto:${productStoreData.LNP}`"
                                style="text-decoration: none; color: grey"
                                >{{ productStoreData.LNP }}</a
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>

                  <v-col lg="6" md="6" sm="12">
                    <v-card flat outlined>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <v-avatar color="orange">
                            <v-icon dark>shopping_cart</v-icon>
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="10" md="10">
                          <v-list-item-content>
                            <v-list-item-title> Sales</v-list-item-title>
                            <v-list-item-subtitle
                              ><a
                                :href="`mailto:${productStoreData.SALES_CARE}`"
                                style="text-decoration: none; color: grey"
                                >{{ productStoreData.SALES_CARE }}</a
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col lg="6" md="6" sm="12">
                    <v-card flat outlined>
                      <v-row>
                        <v-col cols="12" sm="2" md="2">
                          <v-avatar color="#5cc9bf">
                            <v-icon dark>headset</v-icon>
                          </v-avatar>
                        </v-col>
                        <v-col cols="12" sm="10" md="10">
                          <v-list-item-content>
                            <v-list-item-title> Provisioning</v-list-item-title>
                            <v-list-item-subtitle
                              ><a
                                :href="`mailto:${productStoreData.PROVISIONING}`"
                                style="text-decoration: none; color: grey"
                                >{{ productStoreData.PROVISIONING }}</a
                              >
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row> -->
        <!-- Twitter DID Number Provider -->

        <!-- </v-row>-->

        <!-- Top Cards -->

        <!-- <v-row>
          <v-col sm="12">
            <div>
              <v-row>
                <v-col lg="6" md="6" sm="12">
                  <v-card class="elevation-4">
                    <v-card-title class="title font-weight-regular"
                      ><v-icon> bar_chart </v-icon>
                      <span class="lstick"></span> INBOUND TRAFFIC</v-card-title
                    >
                    <v-row align="center" justify="center">
                      <apexcharts
                        type="donut"
                        width="280"
                        :options="options"
                        :series="series"
                      ></apexcharts>
                      <v-card-text>
                        <v-list-item-group>
                          <template v-for="(item, index) in items">
                            <v-list-item :key="item.title">
                              <template>
                                <v-list-item-content>
                                  <v-list-item-subtitle
                                    v-text="item.subtitle"
                                  ></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-list-item-action-text
                                    v-text="item.action"
                                  ></v-list-item-action-text>
                                </v-list-item-action>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="index < items.length - 1"
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-card-text>
                    </v-row>
                  </v-card>
                </v-col>

                <v-col lg="6" md="6" sm="12">
                  <v-card>
                    <v-card-title class="title font-weight-regular"
                      ><v-icon> bar_chart </v-icon>
                      <span class="lstick"></span> OUTBOUND
                      TRAFFIC</v-card-title
                    >
                    <v-row align="center" justify="center">
                      <apexcharts
                        type="donut"
                        width="280"
                        :options="options"
                        :series="series"
                      ></apexcharts>
                      <v-card-text>
                        <v-list-item-group>
                          <template v-for="(item, index) in items">
                            <v-list-item :key="item.title">
                              <template>
                                <v-list-item-content>
                                  <v-list-item-subtitle
                                    v-text="item.subtitle"
                                  ></v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-action>
                                  <v-list-item-action-text
                                    v-text="item.action"
                                  ></v-list-item-action-text>
                                </v-list-item-action>
                              </template>
                            </v-list-item>

                            <v-divider
                              v-if="index < items.length - 1"
                              :key="index"
                            ></v-divider>
                          </template>
                        </v-list-item-group>
                      </v-card-text>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </div>
          </v-col>
        </v-row> -->
      </v-col>
      <v-col lg="3" md="3" sm="12">
        <v-card>
          <a
            class="twitter-timeline"
            data-height="530"
            href="https://twitter.com/DIDNumProvider"
            >Tweets by DIDNumProvider</a
          >
        </v-card>
        <!-- <v-card class="mt-4">
          <v-card-text class="py-3">
            <v-timeline dense class="pt-1">
              <v-timeline-item
                class="pb-2"
                color="#9ad268"
                icon="support_agent"
              >
                <v-row class="pt-1">
                  <v-col>
                    <strong>Customer Support</strong>
                    <div class="caption">
                      {{ productStoreData.CUSTOMER_CARE }}
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item
                class="pb-2"
                color="teal lighten-3"
                icon="person"
              >
                <v-row class="pt-1">
                  <v-col>
                    <strong> Account Manager</strong>
                    <div class="caption mb-2">
                      {{
                        productStoreData.ACC_MGR
                          ? productStoreData.ACC_MGR
                          : "-"
                      }}
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item class="pb-2" color="pink" icon="location_on">
                <v-row class="pt-1">
                  <v-col>
                    <strong>LNP</strong>
                    <div class="caption">{{ productStoreData.LNP }}</div>
                  </v-col>
                </v-row>
              </v-timeline-item>

              <v-timeline-item class="pb-2" color="orange" icon="shopping_cart">
                <v-row class="pt-1">
                  <v-col>
                    <strong>Sales</strong>
                    <div class="caption">{{ productStoreData.SALES_CARE }}</div>
                  </v-col>
                </v-row>
              </v-timeline-item>
              <v-timeline-item class="pb-2" color="#5cc9bf" icon="headset">
                <v-row class="pt-1">
                  <v-col>
                    <strong>Provisioning</strong>
                    <div class="caption">
                      {{ productStoreData.PROVISIONING }}
                    </div>
                  </v-col>
                </v-row>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card> -->
        <loading :active="isLoading" :loader="loader" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import dashboardAPI from "@/services/dashboardAPI.js";
// import VueApexCharts from "vue-apexcharts";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";

export default {
  name: "DashBoard",
  components: {
    Loading,
    // apexcharts: VueApexCharts,
  },
  data() {
    return {
      userDetail: {},
      didHistory: {},
      options: {
        dataLabels: {},
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
          // formatter(val, opts) {
          //   // console.log("val,", val.toFixed(1), "opts", opts.w.globals);
          //   const name = opts.w.globals.labels[opts.seriesIndex];
          //   return [name, val.toFixed(1) + "%"];
          // },
        },
      },
      isLoading: false,
      loader: "bars",
      series: [44, 55, 41, 17, 15, 10],
      tab: null,
      productStoreData: {},
      outboundCallLimitStatus: "",
      items: [
        {
          action: "44",
          subtitle: `DID Net Additions`,
        },
        {
          action: "55",
          subtitle: `New DID Activations(0)`,
        },
        {
          action: "41",
          subtitle: "Inbound Minutes",
        },
        {
          action: "17",
          subtitle: "Average Minutes Per DID",
        },
        {
          action: "15",
          subtitle: "Outbound Minutes",
        },
        {
          action: "10",
          subtitle: "Outbound Traffic Cost",
        },
      ],
    };
  },

  methods: {
    async getProductStoreDetail() {
      try {
        let response = await dashboardAPI.getProductStoreDetail();
        for (let item of response.partyAndContactMechDetails) {
          let arr = Object.keys(item);
          if (arr.length > 0) {
            this.productStoreData[arr[0]] = item[arr[0]].email;
          }
        }
      } catch (error) {
        console.log("====error===", error);
      }
    },
    async getConnectivityListDetail() {
      this.isLoading = true;
      let ConnectivityPayload = {
        accountId: this.userDetail.accountId,
        authToken: this.userDetail.authToken,
      };
      try {
        let response = await dashboardAPI.getConnectivityList(
          ConnectivityPayload
        );
        if (response) {
          if (response.responseData.data.length == 0) {
            this.isLoading = true;
            try {
              let createConnectivity = await dashboardAPI.addConnectivityId(
                ConnectivityPayload
              );
              if (createConnectivity) {
                this.isLoading = false;
              }
            } catch (error) {
              this.isLoading = false;
              console.log("====error===", error);
            }
          } else {
            this.isLoading = false;
          }
        }
      } catch (error) {
        this.isLoading = false;
        console.log("====error===", error);
      }
    },
    async getDIDHistory() {
      let didHistoryDetail = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await dashboardAPI.getDIDHistory(didHistoryDetail);
        this.didHistory = response.data;
      } catch (error) {
        console.log("====error===", error);
      }
    },

    // async getOutboundCallLimit() {
    //   this.isLoading = true;
    //   let graphData = {
    //     accountId: this.userDetail.accountId,
    //     direction: "",
    //   };
    //   try {
    //     let response = await dashboardAPI.getInboundOutboundGraph(graphData);
    //     this.graphList = response.timeList;
    //     this.isLoading = false;
    //   } catch (error) {
    //     console.log("====error===", error);
    //     this.isLoading = false;
    //   }
    // },

    async getOutboundCallLimit() {
      this.isLoading = true;
      let graphData = {
        accountId: this.userDetail.accountId,
      };
      try {
        let response = await dashboardAPI.getOutboundCallLimit(graphData);
        this.outboundCallLimitStatus =
          response.accountLimitsdetails.outbound_trunks == 0
            ? "-"
            : response.accountLimitsdetails.outbound_trunks;
        this.isLoading = false;
      } catch (error) {
        console.log("====error===", error);
        this.isLoading = false;
      }
    },
  },

  mounted() {
    let externalScript = document.createElement("script");
    externalScript.setAttribute(
      "src",
      "https://platform.twitter.com/widgets.js"
    );
    document.head.appendChild(externalScript);

    this.userDetail = JSON.parse(localStorage.getItem("userDetail"));
    this.getProductStoreDetail();
    this.getDIDHistory();
    // this.getInboundOutboundGraph();
    this.getOutboundCallLimit();
    this.getConnectivityListDetail();
  },
};
</script>

<style scoped>
/* .bounds {
  padding-top: 3%;
} */

/* .row {
  margin-right: 0 !important;
  margin-left: 0 !important;
} */

/* Cards */
.custom-card {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  max-width: 100%;
  max-height: 150px;
  border-radius: 10px;
  padding: 28px 3px 7px 10px;
  cursor: pointer;
  color: #fff;
  overflow: hidden;
  min-height: 150px;
  position: relative;
}

.card-refresh-icon {
  position: absolute;
  top: 5px;
  right: 5px;
}

h4 {
  font-size: 14px;
}

.fa-lg {
  font-size: 14px;
  margin-left: 5px;
}

.custom-card-background-icon {
  position: absolute;
  right: 35px;
  bottom: 20px;
  opacity: 0.3;
}
</style>
